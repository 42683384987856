/* eslint-env browser */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import { sendAnalytics, isTestEnvironment } from '../../util';
import { CloseButton } from './styled-components/buttonStyles';
import * as S from './styled-components/imageRotateModalStyles.js';

if (!isTestEnvironment()) {
  Modal.setAppElement('#___gatsby');
}

const VideoModal = ({ modalIsOpen, closeModal, title, vimeoId }) => {
  const onRenderAnalytics = useCallback(
    () => {
      sendAnalytics(
        {
          event_category: 'Navigate',
          event_label: `View Video ${title} ${vimeoId}`,
          image_name: title,
        },
        'click'
      );
    },
    [title, vimeoId]
  );

  useEffect(
    () => {
      onRenderAnalytics();
    },
    [onRenderAnalytics]
  );

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel={title}
      className="modalContent"
      overlayClassName="modalOverlay"
    >
      <CloseButton onClick={closeModal}>
        <div>X</div>
      </CloseButton>

      <S.Title>{title}</S.Title>
      <ReactPlayer
        url={`https://vimeo.com/${vimeoId}`}
        width="100%"
        height="calc(100% - 41px)"
        playing={true}
        style={{ backgroundColor: 'white' }}
      />
    </Modal>
  );
};

VideoModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  vimeoId: PropTypes.number,
};

export default VideoModal;
